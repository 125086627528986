import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refPurchaseOrderListTable = ref(null)
  const refPurchaseOrderListTableFinished = ref(null)
  const refPurchaseOrderListTableFinishedHO = ref(null)
  const refPurchaseOrderListTableEarlyFinished = ref(null)
  const refPurchaseOrderListTableEarlyFinishedNC = ref(null)
  const refPurchaseOrderListTableHO = ref(null)
  const refPurchaseOrderListTableVoid = ref(null)
  const tableColumns = [
    { key: 'Number', sortable: true },
    { key: 'PurchaseRequestNumber', sortable: true },
    { key: 'Supplier', sortable: true },
    { key: 'withPPN', sortable: false },
    { key: 'ShipmentBy', sortable: true },
    { key: 'DeliverySchedule', sortable: true },
    { key: 'BillingAddress', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'Action', sortable: true },
  ]
  const tableColumnsVoid = [
    { key: 'Number', sortable: true },
    { key: 'PurchaseRequestNumber', sortable: true },
    { key: 'Supplier', sortable: true },
    { key: 'withPPN', sortable: false },
    { key: 'ShipmentBy', sortable: true },
    { key: 'DeliverySchedule', sortable: true },
    { key: 'BillingAddress', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'VoidTime', sortable: false },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const perPageHO = ref(10)
  const perPageFinished = ref(10)
  const perPageFinishedHO = ref(10)
  const perPageEarlyFinished = ref(10)
  const perPageEarlyFinishedNC = ref(10)
  const perPageVoid = ref(10)
  const totalPurchaseOrder = ref(0)
  const totalPurchaseOrderHO = ref(0)
  const totalPurchaseOrderFinished = ref(0)
  const totalPurchaseOrderFinishedHO = ref(0)
  const totalPurchaseOrderEarlyFinished = ref(0)
  const totalPurchaseOrderEarlyFinishedNC = ref(0)
  const totalPurchaseOrderVoid = ref(0)
  const currentPage = ref(1)
  const currentPageHO = ref(1)
  const currentPageFinished = ref(1)
  const currentPageFinishedHO = ref(1)
  const currentPageEarlyFinished = ref(1)
  const currentPageEarlyFinishedNC = ref(1)
  const currentPageVoid = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryHO = ref('')
  const searchQueryFinished = ref('')
  const searchQueryFinishedHO = ref('')
  const searchQueryEarlyFinished = ref('')
  const searchQueryEarlyFinishedNC = ref('')
  const searchQueryVoid = ref('')
  const sortBy = ref('id')
  const sortByHO = ref('id')
  const sortByFinished = ref('id')
  const sortByFinishedHO = ref('id')
  const sortByEarlyFinished = ref('id')
  const sortByEarlyFinishedNC = ref('id')
  const sortByVoid = ref('id')
  const isSortDirDesc = ref(true)
  const isSortDirDescHO = ref(true)
  const isSortDirDescFinished = ref(true)
  const isSortDirDescFinishedHO = ref(true)
  const isSortDirDescEarlyFinished = ref(true)
  const isSortDirDescEarlyFinishedNC = ref(true)
  const isSortDirDescVoid = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const startDateFilterHO = ref('')
  const endDateFilterHO = ref('')
  const startDateFilterFinished = ref('')
  const endDateFilterFinished = ref('')
  const startDateFilterFinishedHO = ref('')
  const startDateFilterEarlyFinished = ref('')
  const endDateFilterEarlyFinished = ref('')
  const startDateFilterEarlyFinishedNC = ref('')
  const endDateFilterEarlyFinishedNC = ref('')
  const endDateFilterFinishedHO = ref('')
  const startDateFilterVoid = ref('')
  const endDateFilterVoid = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refPurchaseOrderListTable.value ? refPurchaseOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPurchaseOrder.value,
    }
  })

  const dataMetaHO = computed(() => {
    const localItemsCount = refPurchaseOrderListTableHO.value ? refPurchaseOrderListTableHO.value.localItems.length : 0
    return {
      from: perPageHO.value * (currentPageHO.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageHO.value * (currentPageHO.value - 1) + localItemsCount,
      of: totalPurchaseOrderHO.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCount = refPurchaseOrderListTableFinished.value ? refPurchaseOrderListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCount,
      of: totalPurchaseOrderFinished.value,
    }
  })
  const dataMetaFinishedHO = computed(() => {
    const localItemsCount = refPurchaseOrderListTableFinishedHO.value ? refPurchaseOrderListTableFinishedHO.value.localItems.length : 0
    return {
      from: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + localItemsCount,
      of: totalPurchaseOrderFinishedHO.value,
    }
  })
  const dataMetaVoid = computed(() => {
    const localItemsCount = refPurchaseOrderListTableVoid.value ? refPurchaseOrderListTableVoid.value.localItems.length : 0
    return {
      from: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCount,
      of: totalPurchaseOrderVoid.value,
    }
  })

  const dataMetaEarlyFinished = computed(() => {
    const localItemsCount = refPurchaseOrderListTableEarlyFinished.value ? refPurchaseOrderListTableEarlyFinished.value.localItems.length : 0
    return {
      from: perPageEarlyFinished.value * (currentPageEarlyFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageEarlyFinished.value * (currentPageEarlyFinished.value - 1) + localItemsCount,
      of: totalPurchaseOrderEarlyFinished.value,
    }
  })

  const dataMetaEarlyFinishedNC = computed(() => {
    const localItemsCount = refPurchaseOrderListTableEarlyFinishedNC.value ? refPurchaseOrderListTableEarlyFinishedNC.value.localItems.length : 0
    return {
      from: perPageEarlyFinishedNC.value * (currentPageEarlyFinishedNC.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageEarlyFinishedNC.value * (currentPageEarlyFinishedNC.value - 1) + localItemsCount,
      of: totalPurchaseOrderEarlyFinishedNC.value,
    }
  })

  const refetchData = () => {
    refPurchaseOrderListTable.value.refresh()
  }

  const refetchDataHO = () => {
    refPurchaseOrderListTableHO.value.refresh()
  }

  const refetchDataFinished = () => {
    refPurchaseOrderListTableFinished.value.refresh()
  }
  const refetchDataFinishedHO = () => {
    refPurchaseOrderListTableFinishedHO.value.refresh()
  }
  const refetchDataEarlyFinished = () => {
    refPurchaseOrderListTableEarlyFinished.value.refresh()
  }
  const refetchDataEarlyFinishedNC = () => {
    refPurchaseOrderListTableEarlyFinishedNC.value.refresh()
  }

  const refetchDataVoid = () => {
    refPurchaseOrderListTableVoid.value.refresh()
  }

  watch([
    currentPage, currentPageHO, currentPageFinished, currentPageFinishedHO, currentPageVoid,
    perPage, perPageHO, perPageFinished, perPageFinishedHO, perPageVoid,
    searchQuery, searchQueryHO, searchQueryFinished, searchQueryFinishedHO, searchQueryVoid,
    roleFilter, statusFilter, sortBy, sortByHO, sortByFinished, sortByFinishedHO, sortByVoid,
    isSortDirDesc, isSortDirDescHO, isSortDirDescFinished, isSortDirDescFinishedHO, isSortDirDescVoid,
    startDateFilter, startDateFilterHO, startDateFilterFinished, startDateFilterFinishedHO, startDateFilterVoid,
    endDateFilter, endDateFilterHO, endDateFilterFinished, endDateFilterFinishedHO, endDateFilterVoid,
    currentPageEarlyFinished, currentPageEarlyFinishedNC, perPageEarlyFinished, perPageEarlyFinishedNC, searchQueryEarlyFinished,
    searchQueryEarlyFinishedNC, sortByEarlyFinished, sortByEarlyFinishedNC,
  ], (newValues, oldValues) => {
    // Find the index of the changed property
    const changedIndex = newValues.findIndex((value, index) => value !== oldValues[index])

    // Perform actions based on the changed property
    if (changedIndex !== -1) {
      switch (changedIndex) {
        case 0: // currentPage
          refetchData()
          break
        case 1: // currentPageHO
          refetchDataHO()
          break
        case 2: // currentPageFinished
          refetchDataFinished()
          break
        case 3: // currentPageFinishedHO
          refetchDataFinishedHO()
          break
        case 4: // currentPageVoid
          refetchDataVoid()
          break
        case 5: // perPage
          refetchData()
          break
        case 6: // perPageHO
          refetchDataHO()
          break
        case 7: // perPageFinished
          refetchDataFinished()
          break
        case 8: // perPageFinishedHO
          refetchDataFinishedHO()
          break
        case 9: // perPageVoid
          refetchDataVoid()
          break
        case 10: // searchQuery
          refetchData()
          break
        case 11: // searchQueryHO
          refetchDataHO()
          break
        case 12: // searchQueryFinished
          refetchDataFinished()
          break
        case 13: // searchQueryFinishedHO
          refetchDataFinishedHO()
          break
        case 14: // searchQueryVoid
          refetchDataVoid()
          break
        case 15: // roleFilter
          refetchData()
          break
        case 16: // statusFilter
          refetchData()
          break
        case 17: // sortBy
          refetchData()
          break
        case 18: // sortByHO
          refetchDataHO()
          break
        case 19: // sortByFinished
          refetchDataFinished()
          break
        case 20: // sortByFinishedHO
          refetchDataFinishedHO()
          break
        case 21: // sortByVoid
          refetchDataVoid()
          break
        case 22: // isSortDirDesc
          refetchData()
          break
        case 23: // isSortDirDescHO
          refetchDataHO()
          break
        case 24: // isSortDirDescFinished
          refetchDataFinished()
          break
        case 25: // isSortDirDescFinishedHO
          refetchDataFinishedHO()
          break
        case 26: // isSortDirDescVoid
          refetchDataVoid()
          break
        case 27: // startDateFilter
          refetchData()
          break
        case 28: // startDateFilterHO
          refetchDataHO()
          break
        case 29: // startDateFilterFinished
          refetchDataFinished()
          break
        case 30: // startDateFilterFinishedHO
          refetchDataFinishedHO()
          break
        case 31: // startDateFilterVoid
          refetchDataVoid()
          break
        case 32: // endDateFilter
          refetchData()
          break
        case 33: // endDateFilterHO
          refetchDataHO()
          break
        case 34: // endDateFilterFinished
          refetchDataFinished()
          break
        case 35: // endDateFilterFinishedHO
          refetchDataFinishedHO()
          break
        case 36: // endDateFilterVoid
          refetchDataVoid()
          break
        case 37: // currentPageEarlyFinished
          refetchDataEarlyFinished()
          break
        case 38: // currentPageEarlyFinishedNC
          refetchDataEarlyFinishedNC()
          break
        case 39: // perPageEarlyFinished
          refetchDataEarlyFinished()
          break
        case 40: // perPageEarlyFinishedNC
          refetchDataEarlyFinishedNC()
          break
        case 41: // searchQueryEarlyFinished
          refetchDataEarlyFinished()
          break
        case 42: // searchQueryEarlyFinishedNC
          refetchDataEarlyFinishedNC()
          break
        case 43: // sortByEarlyFinished
          refetchDataEarlyFinished()
          break
        case 44: // sortByEarlyFinishedNC
          refetchDataEarlyFinishedNC()
          break
        default:
          // Default action if none of the specified properties changed
          break
      }
    }
  })

  const fetchPurchaseOrders = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPage.value * (currentPage.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?is_center=false&limit=${perPage.value}&offset=${to}&status=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&search=${searchQuery.value}`, { headers })
      .then(response => {
        totalPurchaseOrder.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseOrdersHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHO.value * (currentPageHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageHO.value}&offset=${to}&status=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&search=${searchQueryHO.value}&is_center=true`, { headers })
      .then(response => {
        totalPurchaseOrderHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseOrdersFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinished.value * (currentPageFinished.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?is_center=false&limit=${perPageFinished.value}&offset=${to}&status=finished&date_start=${startDateFilterFinished.value}&date_end=${endDateFilterFinished.value}&search=${searchQueryFinished.value}`, { headers })
      .then(response => {
        totalPurchaseOrderFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersFinishedHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedHO.value * (currentPageFinishedHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageFinishedHO.value}&offset=${to}&status=finished&date_start=${startDateFilterFinishedHO.value}&date_end=${endDateFilterFinishedHO.value}&search=${searchQueryFinishedHO.value}&is_center=true`, { headers })
      .then(response => {
        totalPurchaseOrderFinishedHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersVoid = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageVoid.value * (currentPageVoid.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageVoid.value}&offset=${to}&status=void&date_start=${startDateFilterVoid.value}&date_end=${endDateFilterVoid.value}&search=${searchQueryVoid.value}`, { headers })
      .then(response => {
        totalPurchaseOrderVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersEarlyFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageEarlyFinished.value * (currentPageEarlyFinished.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageEarlyFinished.value}&offset=${to}&status=EarlyFinishedConfirmed&date_start=${startDateFilterEarlyFinished.value}&date_end=${endDateFilterEarlyFinished.value}&search=${searchQueryEarlyFinished.value}`, { headers })
      .then(response => {
        totalPurchaseOrderEarlyFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersEarlyFinishedNC = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageEarlyFinishedNC.value * (currentPageEarlyFinishedNC.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageEarlyFinishedNC.value}&offset=${to}&status=EarlyFinished&date_start=${startDateFilterEarlyFinishedNC.value}&date_end=${endDateFilterEarlyFinishedNC.value}&search=${searchQueryEarlyFinishedNC.value}`, { headers })
      .then(response => {
        totalPurchaseOrderEarlyFinishedNC.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchRoles = () => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
      .then(response => {
        console.log(response)
        const temp = [
          { label: 'Admin', value: 'admin' },
          { label: 'Author', value: 'author' },
          { label: 'Editor', value: 'editor' },
          { label: 'Maintainer', value: 'maintainer' },
          { label: 'Subscriber', value: 'subscriber' },
        ]
        return temp
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPurchaseOrders,
    fetchPurchaseOrdersHO,
    fetchPurchaseOrdersFinished,
    fetchPurchaseOrdersFinishedHO,
    currentPageEarlyFinishedNC,
    totalPurchaseOrderEarlyFinishedNC,
    fetchPurchaseOrdersVoid,
    fetchRoles,
    tableColumns,
    tableColumnsVoid,
    perPage,
    perPageFinished,
    perPageVoid,
    currentPage,
    currentPageFinished,
    currentPageVoid,
    totalPurchaseOrder,
    totalPurchaseOrderFinished,
    totalPurchaseOrderVoid,
    dataMeta,
    dataMetaFinished,
    dataMetaVoid,
    perPageOptions,
    searchQuery,
    searchQueryFinished,
    searchQueryVoid,
    sortBy,
    sortByFinished,
    sortByVoid,
    isSortDirDesc,
    isSortDirDescFinished,
    isSortDirDescVoid,
    refPurchaseOrderListTable,
    refPurchaseOrderListTableFinished,
    refPurchaseOrderListTableVoid,
    refPurchaseOrderListTableEarlyFinishedNC,
    refPurchaseOrderListTableEarlyFinished,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    refetchDataFinished,
    refetchDataEarlyFinished,
    refetchDataEarlyFinishedNC,
    refetchDataVoid,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startDateFilterFinished,
    endDateFilterFinished,
    startDateFilterVoid,
    endDateFilterVoid,
    perPageHO,
    perPageFinishedHO,
    perPageEarlyFinished,
    perPageEarlyFinishedNC,
    currentPageHO,
    currentPageFinishedHO,
    totalPurchaseOrderFinishedHO,
    totalPurchaseOrderHO,
    dataMetaHO,
    dataMetaFinishedHO,
    searchQueryHO,
    searchQueryFinishedHO,
    sortByHO,
    sortByFinishedHO,
    isSortDirDescHO,
    isSortDirDescFinishedHO,
    refPurchaseOrderListTableHO,
    refPurchaseOrderListTableFinishedHO,
    searchQueryEarlyFinished,
    searchQueryEarlyFinishedNC,
    sortByEarlyFinished,
    sortByEarlyFinishedNC,
    isSortDirDescEarlyFinished,
    isSortDirDescEarlyFinishedNC,
    startDateFilterEarlyFinished,
    endDateFilterEarlyFinished,
    startDateFilterEarlyFinishedNC,
    endDateFilterEarlyFinishedNC,
    dataMetaEarlyFinished,
    dataMetaEarlyFinishedNC,
    fetchPurchaseOrdersEarlyFinishedNC,
    fetchPurchaseOrdersEarlyFinished,
  }
}
